@import '../../theme.scss';
#tamogatok {
	background-color: $iok-sponsor-grey;
	.sponsor-grid {
		display: grid;
/* 		grid-template-columns: repeat(4, 1fr);
		grid-template-rows: repeat(2, 1fr); */
		column-gap: 10%;
		row-gap: 60px;
		max-width: 1100px;
		margin: 0 auto;
		.sponsor {
			width: 100%;
			img {
				height: 100%;
			}
			@include mobile {
				padding: 0 80px;
			}
		}

		&.main-sponsors, &.partner-sponsors {
			grid-template-columns: repeat(3, 1fr);
			grid-template-rows: 1fr;
			max-width: 1000px;
			align-items: center;
			width: 100%;
			text-align: center;
			
			.sponsor {
				height: 90px;
				display: flex;
				justify-content: center;
			}
			.sponsor.w-full {
				grid-column-start: 1;
				grid-column-end: 4;
			}
			@media screen and (max-width: 1200px) {
				grid-template-columns: repeat(2, 1fr);
				max-width: 700px;
				.sponsor.w-full {
					grid-column-end: 3;
				}
			}
			@include mobile {
				display: flex;
				flex-direction: column;
				column-gap: 20px;
				row-gap: 50px;
				.sponsor {
					padding: 0;
					text-align: center;
					height: auto;
					img {
						width: 80%;
					}
				}
			}
		}
		&.partner-sponsors {
			height: 180px;
			grid-template-columns: repeat(4, 1fr);
			max-width: 1000px;
			align-items: center;
			gap: 0;
			width: 100%;
			@media screen and (max-width: 1200px) {
				grid-template-columns: 1fr;
				height: auto;
				row-gap: 50px;
			}
/* 			grid-template-columns: repeat(3, 1fr);
			grid-template-rows: repeat(2, 1fr); */
			.elte-tok {
				height: 180px !important;
			}
			.sponsor {
				text-align: center;
				height: auto;
				img {
					width: auto !important;
				}

			}
		}
		&.itmp-sponsors {
			grid-template-columns: 1fr;
			grid-template-rows: 1fr;
			text-align: center;
			.sponsor {
				padding: 0
			}

		}

	}

	h3 {
		font-weight: 700;
		font-size: 1.6rem;	
		line-height: 1.5;
		text-align: center;
		margin-bottom: 30px;
		@include mobile {
			font-size: 1.3rem;
			margin-top: 30px;
		}
	}
	h2 {
		.highlight {
			color: $bg-primary;
		}
	}
	padding-bottom: 100px;
}