$primary: #bde7ff; //buttons
$light: #fff;
$dark: #363c56;
$dark-more: #202333;
$grey: #4F4F4F;
$lightgrey: #BDBDBD;
$bg-light: #fff;
$bg-primary: #8b7ca6; // header alatti sáv
$bg-secondary: #363c56; // header háttér
$iok-yellow: #fadd99;
$h1-color: $iok-yellow;
$iok-light: #bde7ff;
$iok-lightblue: adjust-color($dark, $lightness: 25%);
$iok-lightgrey: #bde7ff; // info section background
$iok-lightgreen: #a7ede2;
$iok-sponsor-grey: #e9ecef;

/* 

 */
$mobile-breakpoint: 762px;

@mixin mobile {
	@media screen and (max-width: #{$mobile-breakpoint}) {
		@content;
	}
}

@mixin desktop {
	@media screen and (min-width: #{$mobile-breakpoint}) {
		@content;
	}
}